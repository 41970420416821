import(/* webpackMode: "eager" */ "/usr/src/app/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/components/Content/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/components/Content/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/components/Cookie/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/components/Widget/Chatwoot/index.jsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/@next+third-parties@14.2.28_next@14.2.5_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/@next+third-parties@14.2.28_next@14.2.5_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/@next+third-parties@14.2.28_next@14.2.5_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["SecureCookiesProvider"] */ "/usr/src/app/node_modules/.pnpm/next-client-cookies@1.1.1_next@14.2.5_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-client-cookies/dist/provider.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/usr/src/app/provider/auth.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/provider/react-query.provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/provider/translations.provider.tsx");
